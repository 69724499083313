import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Ad } from '../../responsive/atoms/Ad';
import {
  mapContentToCardData,
  removeAuthorsAndDateFromCardData
} from '../../../domain/utils/contentUtils';
import { ContentPackageWrapper } from '../../responsive/organisms/ContentPackageWrapper';
import { ContainerFluid } from '../../responsive/atoms/ContainerFluid';
import { GridColumn, GridRow } from '../../responsive/atoms/Grid';
import { CardItemVerticalLegacy } from '../../responsive/organisms/CardItemVerticalLegacy';
import { ContainerMain } from '../../responsive/atoms/ContainerMain';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { ParagraphWrapper } from '../ParagraphWrapper';

const HighVolumeHeroPackageInner = ({ data, content, isAboveTheFold }) => {
  const [breakpoint] = useBreakpoint();

  const cardGlobalLargeComponent = data.videoCardGlobalLarge?.entity && (
    <ContainerFluid>
      <GridRow gutter={[null, null, null, 'l']}>
        <GridColumn width={12} childrenMarginBottom="l">
          <CardItemVerticalLegacy
            size="large"
            data={removeAuthorsAndDateFromCardData(
              mapContentToCardData(data.videoCardGlobalLarge.entity)
            )}
            backgroundColor={data.backgroundColor}
            isAboveTheFold={isAboveTheFold}
          />
        </GridColumn>
      </GridRow>
    </ContainerFluid>
  );

  const styles = {
    ad: css`
      position: relative;
      display: block;
      text-align: center;
      padding: 50px 0;
      float: left;
      width: 100%;
      background: ${data.backgroundColor === 'black'
        ? 'rgba(255, 255, 255, 0.03)'
        : 'rgba(0, 0, 0, 0.05)'};
    `
  };
  const contentBackgroundColor = content.backgroundColor
    ? content.backgroundColor
    : data.backgroundColor;
  return (
    <ContentPackageWrapper
      contentPackageHeader={data.contentPackageHeader}
      backgroundColor={
        content.backgroundColor === 'black'
          ? 'true_black'
          : contentBackgroundColor
      }
      extraStyles={css`
        ${facePaint({
          'contain-intrinsic-size': [
            '0 4072px',
            '0 2317px',
            '0 2598px',
            '0 2988px'
          ]
        })}
      `}
      headerChildren={cardGlobalLargeComponent}
    >
      <ContainerMain>
        <GridRow gutter={[null, null, null, 'l']}>
          <GridColumn width={[12, 7, 7, 7]} childrenMarginBottom="l">
            {data.videoCardGlobalMedium &&
              data.videoCardGlobalMedium.map((card, index) => {
                const key = `videoCardGlobalMedium_${index}`;
                return card.entity ? (
                  <CardItemVerticalLegacy
                    size="medium"
                    data={removeAuthorsAndDateFromCardData(
                      mapContentToCardData(card.entity)
                    )}
                    backgroundColor={data.backgroundColor}
                    key={key}
                    aspect={index === 0 ? '4x3' : '16x9'}
                    canBeAnimated={
                      index === 0 &&
                      (breakpoint.name === 'tablet' ||
                        breakpoint.name === 'mobile')
                    }
                    imageMaxWidths={{
                      mobile: 703,
                      tablet: 537,
                      small_desktop: 658,
                      large_desktop: 839
                    }}
                  />
                ) : null;
              })}
          </GridColumn>
          <GridColumn width={[12, 5, 5, 5]} childrenMarginBottom="l">
            {data.articleCardGlobalSmall &&
              data.articleCardGlobalSmall.map((card, index) => {
                const key = `articleCardGlobalSmall_${index}`;
                return card.entity ? (
                  <CardItemVerticalLegacy
                    size={index === 0 ? 'small' : 'medium'}
                    data={removeAuthorsAndDateFromCardData(
                      mapContentToCardData(card.entity)
                    )}
                    backgroundColor={data.backgroundColor}
                    key={key}
                    aspect="16x9"
                    canBeAnimated={
                      index === 0 &&
                      (breakpoint.name === 'small_desktop' ||
                        breakpoint.name === 'large_desktop')
                    }
                    imageMaxWidths={{
                      mobile: 703,
                      tablet: 374,
                      small_desktop: 461,
                      large_desktop: 585
                    }}
                  />
                ) : null;
              })}
            <div css={styles.ad}>
              <Ad
                key={`high_volume_hero_package__${data.id}`}
                id={`high_volume_hero_package__${data.id}`}
                content={content}
              />
            </div>
          </GridColumn>
        </GridRow>
      </ContainerMain>
    </ContentPackageWrapper>
  );
};

HighVolumeHeroPackageInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

HighVolumeHeroPackageInner.defaultProps = {
  isAboveTheFold: false
};

export const HighVolumeHeroPackage = ParagraphWrapper(
  HighVolumeHeroPackageInner
);
